<template>
    <header :class="absolute ? 'absolute top-0': ''" class="inset-x-0 z-50 bg-white border-b border-gray-300">
        <nav class="navbar mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
            <div class="navbar-start">
                <div class="">
                    <div aria-label="Menu" tabindex="0" role="button" class="btn btn-ghost lg:hidden"
                         @click="showMenu = !showMenu">
                        <svg v-if="!showMenu" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M4 6h16M4 12h8m-8 6h16"/>
                        </svg>
                        <i v-else class="fa-solid fa-xmark text-lg"></i>
                    </div>
                    <ul v-show="showMenu" tabindex="0"
                        class="menu menu-sm dropdown-content mt-16 z-[20] p-2 pt-6 bg-white fixed left-0 right-0 bottom-0 top-16">
                        <li v-for="item in navigation" :key="item.name" class="mb-4">
                            <!-- Check if item has children -->
                            <template v-if="item.children && item.children.length">
                                <span
                                    class="text-gray-900 dark:text-white uppercase cambay-bold hover:bg-white hover:text-primary justify-center">{{
                                        item.name
                                    }}</span>
                                <li class="justify-center" v-for="child in item.children" :key="child.name">
                                    <a :href="child.href">{{ child.name }}</a>
                                </li>
                            </template>
                            <!-- Otherwise, render a normal link -->
                            <template v-else>
                                <a :href="item.href"
                                   class="text-gray-900 dark:text-white uppercase cambay-bold hover:bg-white hover:text-primary justify-center">
                                    {{ item.name }}
                                </a>
                            </template>
                        </li>
                    </ul>
                </div>
                <ApplicationLogo></ApplicationLogo>
            </div>
            <div class="navbar-center hidden lg:flex">
                <ul class="menu menu-horizontal px-1">
                    <li v-for="item in navigation" :key="item.name">
                        <!-- Check if item has children -->
                        <template v-if="item.children && item.children.length">
                            <PopoverGroup class="inset-x-0 bottom-0 px-4">
                                <div class="flex h-full justify-center space-x-8">
                                    <Popover :key="item.name" class="flex" v-slot="{ open }">
                                        <div class="relative flex">
                                            <PopoverButton
                                                class="outline-none text-gray-900 dark:text-white uppercase cambay-bold hover:bg-white hover:text-primary">
                                                {{ item.name }}
                                            </PopoverButton>
                                        </div>
                                        <transition enter-active-class="transition ease-out duration-200"
                                                    enter-from-class="opacity-0" enter-to-class="opacity-100"
                                                    leave-active-class="transition ease-in duration-150"
                                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <PopoverPanel
                                                class="absolute inset-x-0 left-[calc(50%-24rem)] top-full text-sm text-gray-500 w-[48rem] z-10">
                                                <div class="relative bg-white rounded-xl shadow-xl">
                                                    <div class="mx-auto max-w-7xl px-8">
                                                        <div class="grid grid-cols-3 gap-x-8 gap-y-10 py-6">
                                                            <div v-for="child in item.children" :key="child.name"
                                                                 class="group relative">
                                                                <a :href="child.href">
                                                                    <div v-if="child.imagelink"
                                                                         class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                                        <img :src="child.imagelink"
                                                                             :alt="child.description"
                                                                             class="object-cover object-center"/>
                                                                    </div>
                                                                    <p class="mt-4 block font-medium text-gray-900">
                                                                        <span class="absolute inset-0 z-10"
                                                                              aria-hidden="true"/>
                                                                        {{ child.name }}
                                                                    </p>
                                                                    <p aria-hidden="true" class="mt-1">
                                                                        {{ child.description }}</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopoverPanel>
                                        </transition>
                                    </Popover>
                                </div>
                            </PopoverGroup>
                        </template>
                        <!-- Otherwise, render a normal link -->
                        <template v-else>
                            <a :href="item.href"
                               class="text-gray-900 dark:text-white uppercase cambay-bold hover:bg-white hover:text-primary">
                                {{ item.name }}
                            </a>
                        </template>
                    </li>
                </ul>
            </div>
            <div class="navbar-end sm:w-auto sm:flex sm:justify-end">
                <a :href="route('shoppingcart.preview')" aria-label="Go to shopping cart"
                   class="block rounded-lg px-3 text-base font-semibold text-gray-900 dark:text-white hover:bg-white hover:text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                    </svg>
                </a>
            </div>
        </nav>
    </header>
</template>


<script setup>

import {computed, ref} from "vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import {usePage} from "@inertiajs/vue3";
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from "@headlessui/vue";

const props = defineProps({
    absolute: Boolean,
});

let showMenu = ref(false);

const page = usePage();
const categories = page.props.categories;

const navigation = [
    {name: 'Home', href: route('home')},
    // {name: 'Standing Desks', children: categories},
    {
        name: 'Standing desks', children: [
            {
                name: 'Zit-sta bureau',
                href: route('landing.standing'),
                description: 'Ontdek de voordelen van onze zit-sta bureaus die en transformeer je werkplek.'
            },
            {
                name: 'Verstelbare bureaus',
                href: route('landing.adjustable'),
                description: 'Wissel moeiteloos tussen zittend en staand werken voor optimaal comfort.'
            },
            {
                name: 'Ergonomische bureaus',
                href: route('landing.ergonomic'),
                description: 'Creëer een werkplek die je gezondheid beschermt en je prestaties verbetert.'
            },
        ]
    },
    {name: 'Over ons', href: route('about')},
    {name: 'Blog', href: route('blogs')},
    // {name: 'Contacteer ons', href: route('contact')},
]
</script>
