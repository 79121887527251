<template>
    <footer class="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div>
                    <ApplicationLogo variant="white"></ApplicationLogo>
                    <div class="mt-10 flex flex-col gap-4">
                        <img src="/images/payments/Mollie.png" class="w-1/3" alt="Payments by Mollie">
                        <div class="grid grid-cols-7 gap-2 w-2/3">
                            <img src="/images/payments/Bancontact.png" class="" alt="Bancontact"/>
                            <img src="/images/payments/ING.png" class="" alt="ING"/>
                            <img src="/images/payments/KBC.png" class="" alt="KBC"/>
                            <img src="/images/payments/Belfius.png" class="" alt="Belfius"/>
                            <img src="/images/payments/Visa.png" class="" alt="Visa"/>
                            <img src="/images/payments/Mastercard.png" class="" alt="Mastercard"/>
                            <img src="/images/payments/Ideal.png" class="" alt="iDeal"/>
                        </div>
                        <p class="text-gray-300 hover:text-white"><a href="https://libaro.be" target="_blank" rel="nofollow">Made by Libaro</a></p>
                    </div>

                </div>
                <div class="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
                    <div class="md:grid md:grid-cols-3 md:gap-8">
                        <div>
                            <h3 class="text-sm font-semibold leading-6 text-white ">Ons bedrijf</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.company" :key="item.name">
                                    <a :href="item.href"
                                       class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.legal" :key="item.name">
                                    <a :href="item.href"
                                       class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-sm font-semibold leading-6 text-white">Blog</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="blog in blogs" :key="blog.id">
                                    <a :href="route('blog',{slug:blog.slug})"
                                       class="text-sm leading-6 text-gray-300 hover:text-white">{{ blog.title }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";

const footerNavigation = {
    company: [
        {name: 'Over ons', href: route('about')},
        {name: 'Veelgestelde vragen', href: route('faq')},
        {name: 'Support', href: route('contact')},
    ],
    legal: [
        {name: 'Cookies', href: route('cookies')},
        {name: 'Privacy', href: route('privacy')},
        {name: 'Terms', href: route('terms')},
    ],
}

const page = usePage();
const blogs = computed(() => page.props.blogs)
</script>
