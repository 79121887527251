<template>
    <div v-if="discount?.id" class="flex items-center gap-x-6 bg-gradient-to-r from-primary to-secondary px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p class="text-sm leading-6 text-white uppercase">
            <strong class="font-semibold"><span v-if="discount.type === 'amount'">€</span>{{discount.discount}}<span v-if="discount.type === 'percent'">%</span> korting op onze standing desks t.e.m. {{ new Date(discount.till).toLocaleDateString('en-GB') }}</strong>
        </p>
        <div class="flex flex-1 justify-end">
        </div>
    </div>
</template>

<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

const page = usePage();
const discount = computed(() => page.props.discount)
</script>
